.geoLocationInfoDataItemCombo {
    height: 100%;
    width: 100%;

    .geoLocationInfoDataItemHeader {
        align-items: center;
        display: flex;
        height: 100px;
    }
    
    .geoLocationInfoDataItemData {
        display: flex;
        height: calc(100% - 210px);
        margin-top: 20px;
        overflow: hidden;
        padding-top: 10px;
        width: 100%;

        ul {
            background: #fff;
            height: 100%;
            overflow-y: auto;
            width: 100%;
            
            li {
                color: #B4B4B4;
                cursor: pointer;
                padding: 5px;
                text-align: start;
    
                &:hover, &.selected {
                    background: #E6F2FF;
                    color: #007AFF;
                }
            }
        }
    }

    .geoLocationInfoDataItemFooter {
        height: 70px;
        margin-top: 10px;
        text-align: end;

        .inputAddContainer {
            align-items: center;
            display: flex;
        }
    } 
}
.statementPaymentsModal {
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  .statementPaymentsModalContent {
    overflow: auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    width: 500px;
    max-height: 90vh;
    position: relative;

    & > .icon {
      right: 20px;
      top: 20px;
      position: absolute;
    }
  }
}

.mapMarker {
    cursor: pointer;
    display: flex;
    height: 0;
    width: 0;

    &.order {
        z-index: 1;
        
        img {
            left: -11px;
            top: -32px;
        }
    }
    
    img {
        height: 32px;
        position: relative;
        width: 22px;
    }
}
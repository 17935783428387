.statementList {
  .statementCard {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding-bottom: 10px;
    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
    .statementItem {
      display: flex;
      flex-direction: column;
      gap: 5px;
      min-width: 100px;
      .statementItemHeader {
        color: #808080;
      }
    }
    .statementItemControls {
      margin: 0 0 0 auto;
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }
}
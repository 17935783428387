.depotPereferialUser {
    .depotPereferialUserControls {
        align-items: center;
        display: flex;
        height: 40px;
        justify-content: center;
        padding: 20px;
        position: relative;
        width: 100%;
    }
}
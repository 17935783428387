.waiterButton {
    cursor: pointer;
    display: inline-block;

    button {
        border: none;
        border-radius: 3px;
        cursor: pointer;
        height: 37px;
        float: right;
        font-size: 14px;
        outline: none;
        right: 0;
        width: 92px;

        &:hover {
            &.confirm {
                background: #389aff;
            }
            
            &.disabled {
                background: #e2e2e2;
            }
        }

        &.default {
            background: #fff;
            border: 1px solid #389aff;
            color: #717171;
        }

        &.confirm {
            background: #007aff;
            color: #fff;
        }

        &.disabled {
            cursor: default;
            background: #e2e2e2;
            color: #fff;
        }
    }

    .iconWaiter {
        height: 20px;
        margin: 3px;
        position: relative;
        width: 20px;

        &:hover {
            &.delete {
                background: #007aff;
            }

            &.apply {
                background: url("../../../assets/images/apply_active.png") no-repeat;
            }
        }

        &.add {
            background: #007aff;
            mask: url("../../../assets/images/icon_add.svg") no-repeat;
        }

        &.delete {
            background: #c4c4c4;
            mask: url("../../../assets/images/delete.svg") no-repeat;
        }

        &.apply {
            background: url("../../../assets/images/apply.png") no-repeat;
        }
    }
}
.depotIntegrationInfoItemData {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    
    .depotIntegrationInfoItem {
        padding: 5px 0;
        width: calc(50% - 10px);
    }
}
.billingComponent {
  .loadControl {
    display: flex;
    align-items: center;
    gap: 10px;

    .inputControl {
      width: auto;
    }
  }
}
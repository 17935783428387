.mapSearchControl {
    background: #fff;
    display: flex;
    padding: 20px;
    position: relative;
    width: 350px;

    .search {
        height: 70px;
        width: 100%;
    }

    .suggestedAddresses {
        background: #fff;
        position: absolute;
        right: 0;
        top: 120px;
        width: 390px;

        .title {
            height: 20px;
            padding: 0 10px;
            text-align: start;
        }

        ul {
            background: #fff;
            list-style-type: none;
            line-height: normal;
            margin: 0;
            max-height: 220px;
            overflow: hidden;
            overflow-y: auto;
            padding: 10px;
            text-align: start;

            li {
                background: #f8f8f8;
                border: 1px solid #dbdbdb;
                cursor: pointer;
                font-size: 14px;
                line-height: normal;
                list-style: none;
                overflow: hidden;
                padding: 10px;
                text-overflow: ellipsis;
                white-space: nowrap;
                
                &:hover, &.active {
                    border-color: #bcd5a8;
                    color: #a2b891;
                }
            }
        }
    }
}
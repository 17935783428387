.depotIntegrationInfoItems {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-height: calc(100% - 40px);
    width: 100%;

    .depotIntegrationInfoItemsHeader {
        width: 100%;
    }
}
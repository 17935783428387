.inputControl {
    display: flex;
    flex-direction: column;
    width: 100%;

    .title {
        text-align: start;
    }

    .inputItem {
        display: flex;
    }
}
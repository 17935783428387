html, body, #fullheight {
    min-height: 100% !important;
    height: 100%;
}

body {
    background-color: #f2f2f2;
    font-family: sans-serif;
    margin: 0;
    padding: 0;

    .rtl {
        direction: rtl;
    }
    
    .ltr {
        direction: ltr;
    }

    #root {
        height: 100%;
    }
}
.dropdown {
    width: 100%;
    
    .dropdownContainer {
        display: flex;        
        position: relative;        
        width: 100%;

        input {
            width: 100%;
        }

        .icon {
            background: url("../../../assets/images/check_header.png") no-repeat 50% 50%;
            height: 7px;
            position: absolute;
            right: 5px;
            top: calc(50% - 3.5px);
            width: 7px;

            &.open {
                transform: rotate(180deg);
            }
        }
    
        ul {
            list-style-type: none;
            max-height: 300px;
            overflow-y: auto;
            position: absolute;
            top: calc(3rem + 2px);
            width: 100%;
            z-index: 1;
    
            li {
                background: #fff;
                color: #B4B4B4;
                cursor: pointer;
                padding: 5px;
                text-align: start;
    
                &:hover, &.selected {
                    background: #E6F2FF;
                    color: #007AFF;
                }

                &.deleted {
                    text-decoration: line-through;
                }
            }
        }
    }
}
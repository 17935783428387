.customerServiceProductControl {
  .showMenu {
    margin: 10px 0 0 0;
  }
  .headerSection {
    .searchBox {
      max-width: 300px;
      .searchBoxContainer {
        width: 100%;
        form {
          width: 100%;
        }
      }
    }
    .categories {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      .category {
        cursor: pointer;
      }
    }
  }
  .productsContainer {
    .categoriesSection {
      .categoriesSectionName {
        margin: 20px 0 10px 0;
        font-weight: 600;
      }
      .productPreview {
        margin-bottom: 10px;
      }
    }
  }
}
.app-header {
    align-items: center;
    background-color: #fff;
    box-shadow: 1px 1px 1px rgb(0 0 0 / 20%);
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    height: 50px;
    position: relative;
    z-index: 12;

    .logo {
        display: flex;

        img {
            max-height: 38px;
            max-width: 125px;
        }

        &.empty {
            background: url("../../../assets/images/avatar.png") no-repeat 50% 50% #b6b6b6;
        }
    }

    .app-header-Item {
        padding: 12px;
    }
}
menu.headerMenu {
    display: inline-block;
    font-family: "Open Sans Hebrew", serif, sans-serif;
    flex: 84;
    height: 40px;
    margin: 16px 0 0;
    overflow: hidden;
    padding: 0;
    text-align: center;
    width: 100%;
    z-index: 12;
  
    li {
      display: inline-block;
      letter-spacing: 0.5px;
      position: relative;
      padding: 9px 14px 0;
  
      .menuItemTitle {
        border-bottom: 3px solid transparent;
        color: #c4c4c4;
        cursor: pointer;
        font-size: 12px;
        font-weight: 600;
        transition: all ease-out 0.5s;
        text-decoration: none;
      }
  
      .menuItemIcon {
        background: #ff5b2e;
        border-radius: 9px;
        color: #fff;
        font-size: 11px;
        height: 18px;
        left: -5px;
        padding-top: 1px;
        position: absolute;
        text-align: center;
        top: 0px;
        width: 18px;
      }
  
      &.active {
        .menuItemTitle {
          border-color: #007aff;
          color: #444;
          margin-top: 4px;
        }
      }
    }
  }
  
  @media (max-width: 640px) {
    menu.headerMenu {
      display: none;
    }
  }
  
.app-container {
    height: 100%;

    .app-content {
        height: calc(100% - 50px);
        overflow: hidden;
        overflow-y: auto;

        input {
          border: 1px solid #dfe3e7;
          border-radius: 0.267rem;
          font-size: 1rem;
          height: 3rem;
          margin: 0;
          outline: none;
          padding: 0 10px;

          &:focus {
            border: 1px solid #dfe3e7;
            box-shadow: none;
          }
        }

        ul {
          list-style-type: none;
          margin-block-start: initial;
          margin-block-end: initial;
          padding-inline-start: initial;
        }

        .overflowHiddenText {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        
        .overflowNextRowText {
          word-wrap: break-word
        }

        .title {
          color: #475f7b;
          height: auto;
        }
    }

    .toast {
        background: #bd362f !important;
        border-radius: 5px !important;
        box-shadow: none !important;
        min-width: 250px;
        opacity: 0.8 !important;
        padding: 10px 10px !important;
        transition: none !important;
      
        .Toastify__toast-body {
          text-align: start;
        }
      
        button {
          margin-left: 20px;
      
          &.disabled {
            background: #e2e2e2;
            color: #fff;
          }
        }
    }
}
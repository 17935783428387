.confirmDialog {
    align-items: center;
    background: hsla(0,0%, 39.2%, 1);
    display: flex;
    font-size: 12px;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}
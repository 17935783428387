.searchBox {
  padding: 10px 0;
  position: relative;
  width: 100%;

  .searchBoxContainer {
    align-items: center;
    background: #dedede;
    display: flex;
    position: relative;

    //Remove clear button
    input[type="search"]::-ms-clear {
      display: none;
    }
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
      display: none;
    }

    .searchBoxInput {
      background: #dedede;
      height: 30px;      
    }

    .searchBoxIcon {
      background: url("../../../assets/images/search_blue_small.png") no-repeat /*9px 5px  #007aff*/;
      cursor: pointer;
      height: 15px;
      margin: 0px 10px;
      position: relative;
      width: 15px;
    }

    .clearSearch {
      background: url("../../../assets/images/clear_text.png") no-repeat;
      cursor: pointer;
      height: 15px;
      position: absolute;
      right: 9px;
      top: calc(50% - 7px);
      width: 15px;
    }

    ::placeholder {
      color: #9a9a9a;
    }
  }

  .searchBoxFilters {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .filterItem {
      align-items: center;
      border: 1px solid #007AFF;
      border-radius: 21px;
      color: #007AFF;
      cursor: pointer;
      display: inline-flex;
      font-weight: bold;
      font-size: 14px;
      height: 32px;
      line-height: normal;
      line-height: initial;
      margin: 3px 7px;
      padding: 5px 10px;

      &.selected {
        background: #007AFF;
        color: #fff;
        font-weight: 400;
        font-size: 0.8em;
      }
    }
  }
}

.rtl {
  .searchBox {
    .searchBoxContainer {
      .clearSearch {
        left: 9px;
        right: auto;
      }
    }
  }
}

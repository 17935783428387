.depotIntegration {
    height: 100%;    
    width: 100%;

    .searchContainer {
        align-items: flex-end;
        display: flex;
        height: 70px;
        padding: 20px 20px 0 20px;
        width: 350px;

        .waiterButton {
            button {
                height: 3rem;
            }
        }
    }

    .dataContainer {
        max-height: calc(100% - 70px);
        padding: 0 20px;
        width: 50%;
    }
}
.mapControl {
    height: 400px;
    position: relative;
    width: 100%;
    
    .mapSearchControl {
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 1;
    }
}
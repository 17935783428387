.createStatementFormModal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  .createStatementFormModalContent {
    padding: 20px 0 20px 20px;
    border-radius: 10px;
    max-height: 90vh;
    overflow: auto;
    width: 95vw;
    background: #fff;
    position: relative;

    .icon {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    .createStatementForm {
      overflow: auto;
      height: 100%;
    }
  }
}
.checkBox {
    align-items: center;
    display: flex;

    input[type=checkbox] {
        cursor: pointer;
        height: initial;
    }

    .title {
        cursor: pointer;
        margin: 0 5px;
    }
}
.statementPayments {
  overflow: auto;
  .paymentContent {
    margin: 10px 0 0 0;

    .paymentItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 10px 0;

      input {
        height: 30px;
        width: 100px;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 0 10px;
        outline: none;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      &.addPayment {
        color: #007aff;
        cursor: pointer;
      }
    }
    .buttons {
      margin: auto 0 0 0;
    }
  }
}
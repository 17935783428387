.modalAddDish {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  .modalAddDishContent {
    border-radius: 10px;
    max-height: 70vh;
    max-width: 800px;
    width: 100%;
    overflow: auto;
    background: #fff;
    position: relative;
    .iconClose {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .searchBox {
      input {
        border: none !important;
      }
    }
  }
}
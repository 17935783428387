.geoLocationInfoData {
    display: grid;
    grid-template-columns: 60% 40%;
    text-align: center;
    width: 100%;

    .geoLocationInfoDataContainer {
        width: 100%;

        &.street {
            margin: 0 20px;
            padding: 0 20px;
        }

        .geoLocationInfoDataItems {
            display: grid;
            grid-gap: 10px;
            grid-template-columns: repeat(auto-fill, 31%);
            margin: 10px 20px;
            width: 100%;
    
            @media (max-width: 1300px) {
                flex-wrap: wrap;
            }

            &.address {
                grid-template-columns: repeat(auto-fill, calc(50% - 20px));
            }
    
            .geoLocationInfoDataItem { 
                display: flex;
                flex-direction: column;
                height: 410px;
            
                .title {
                    height: 20px;
                    text-align: start;
                }
    
                .geoLocationInfoDataItemContainer {
                    border: 1px solid #c4c4c4;
                    height: calc(100% - 51px);
                    padding: 15px;
                }
            }
        }
    
        .geoLocationInfoDataControls{
            align-items: center;
            display: flex;
            height: 80px;
            justify-content: center;
        }
    }    
}
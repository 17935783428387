.headerUserMenu {
    .headerUserMenuTitle {
        color: #007aff;    
        cursor: pointer;
        display: flex;
        font-weight: 600;

        .menuArrow {
            background: url("../../../assets/images/check_header_a.png");
            height: 6px;
            margin: 4px 0 0 5px;
            width: 7px;
      
            &.open {
              background: url("../../../assets/images/check_header.png");
              min-width: 7px !important;
            }
        }
    }

    ul {
        background: #fff;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
        color: #a1a1a1;
        cursor: default;
        position: absolute;
        top: 35px;
        width: 150px;
        padding: 12px 0 12px 12px;
        font-size: 12px;
        z-index: 7;
    
        li {
            align-items: center;
            cursor: pointer;
            display: flex;
            padding: 0 5px 10px 0;

            a {
                align-items: center;
                display: flex;
            }
        
            .icon {
                height: 15px;
                margin-left: 0;
                margin-right: 10px;
                width: 13px;
        
                &.logoutIcon {
                    background: transparent url("../../../assets/images/logout.png") no-repeat;
                }
            }
        
            &:hover {
                color: #444444;
        
                .icon {
                    &.logoutIcon {
                        background: transparent
                        url("../../../assets/images/logout_active.png") no-repeat;
                    }
                }
            }
        
            &:last-child {
                padding: 0 5px 0 0;
            }
        }
    }
}
.login-wrapper {  
    &.inProcess {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  
    .form-signin {
      direction: ltr !important;
      max-width: 380px;
      margin: 53px auto 0;
    
      .form-signin-heading {
        margin-bottom: 13px;
        color: #646464;
        font-size: 20px;
        float: left;
      }
    
      .forgot-password {
        text-align: right;
    
        a {
          font-size: 12px;
          color: #b2b2b2;
    
          &:hover {
            text-decoration: underline;
          }
        }
      }
    
      .remember-me {
        font-weight: normal;
        font-size: 12px;
        color: #646464;
        margin-left: 20px;
      }
    
      .form-control {
        position: relative;
        font-size: 16px;
        height: auto;
        padding: 10px;
        box-sizing: border-box;
    
        &:focus {
          z-index: 2;
        }
      }
    
      input[type="text"] {
        width: 100%;
        margin-bottom: 10px;
        border: 1px solid #d2d2d2 !important;
      }
    
      input[type="password"] {
        width: 100%;
        border: 1px solid #d2d2d2 !important;
      }
      input[type="password"],
      input[type="text"] {
        background-color: #ffffff;
        color: #646464 !important;
        outline: none !important;
        box-shadow: none !important;
      }
    
      .btn-block {
        margin-top: 40px;
        display: flex;
        align-items: center;
    
        .remember {
          align-items: flex-start;
          display: flex;
          flex: 1;
          flex-direction: column;
    
          .checkContainer {
            position: relative;
            cursor: pointer;
    
            input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
    
              &:checked ~ .checkmark {
                background-color: #2196f3;
              }
    
              &:checked ~ .checkmark:after {
                display: block;
              }
            }
    
            /* On mouse-over, add a grey background color */
            &:hover input ~ .checkmark {
              background-color: #2196f3;
            }
          }
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 14px;
            width: 14px;
            border-radius: 2px;
            background-color: #2196f3;
    
            &:after {
              content: "";
              position: absolute;
              display: none;
            }
    
            &:after {
              left: 5px;
              top: 2px;
              width: 5px;
              height: 9px;
              border: solid white;
              border-width: 0 2px 2px 0;
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
        }
    
        .btn-login {
          width: 92px;
          height: 37px;
          float: right;
          font-size: 14px;
          color: #fff;
          background: #007aff;
          border: none;
          border-radius: 3px;
          outline: none;
          right: 0px;
          cursor: pointer;
    
          span {
            vertical-align: middle;
          }
    
          img {
            vertical-align: middle;
            margin-left: 5px;
          }
    
          &:hover {
            background: #389aff;
          }
    
          &:active {
            background: #0066d6;
          }
        }
      }
    
      .error {
        border: 1px solid #a94442 !important;
      }
    
      p {
        margin: 0 0 7px 0;
        color: #a94442;
        font-size: 14px;
    
        &:last-of-type {
          margin: 10px 0 7px 0;
        }
      }
    
      ::placeholder {
        /* Firefox, Chrome, Opera */
        color: #646464;
      }
    
      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #646464;
      }
    
      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #646464;
      }
    }
  }
  
#login {
    background: #ebebeb;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  #login-header {
    min-height: 120px;
    height: 30%;
    background: url("../../../assets/images/logo_login.png") #fff no-repeat 50%
      79%;
  }
  
  #login-content {
    flex: 1;
    height: calc(70% - 53px);
    margin: auto;
    min-height: 250px;
    position: relative;
    width: 90%;
  }
  
  .login-footer {
    border-top: 1px solid #c7c7c7;
    color: #a8a8a8;
    height: 30px;
    font-size: 12px;
    margin: auto;
    text-align: center;
    width: 85%;
  }
  